#maps-autocomplete {
  width: 100%;
  max-width: 400px;

  .css-1g6gooi {
    height: 40px;
  }

  .css-yk16xz-control,
  .css-2b097c-container {
    background-color: $background;

    border: 1px solid $light;

    border-radius: $border-radius;
  }

  .css-1hwfws3 {
    display: flex;
    align-items: center;
  }
}
