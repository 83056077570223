#auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  .box {
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;

    width: 100%;
    max-width: 500px;

    .error {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      span {
        color: $error;
      }
    }

    form {
      padding: 15px;

      .forgot-password {
        width: 100%;
        text-align: right;
        cursor: pointer;
        margin: -10px 0 10px 0;
      }

      .marketing-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
      }
    }

    .password-section {
      padding-top: 10px;
      .back-btn {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .reset_password_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    padding: 25px;
  }

  .auth-buttons {
    width: 100%;
    display: flex;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      background-color: $disabled;

      cursor: pointer;

      &.active {
        background-color: unset;
        border-radius: $border-radius;
      }
    }
  }

  .google-auth {
    display: flex;
    justify-content: center;

    .auth-content {
      display: flex;
      padding: 15px 25px;
      margin: 10px;
      gap: 15px;
      align-items: center;
      cursor: pointer;

      box-shadow: $box-shadow;
      border-radius: $border-radius;

      transition-duration: 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      .text {
        font-weight: 500;
        color: $muted;
      }

      .icon {
        height: 30px;
        width: 30px;
      }
    }
  }
}
