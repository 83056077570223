#create-nft {
  position: fixed;
  height: 100vh;
  width: 150vw;

  max-height: 100vh;
  overflow-y: scroll;

  top: 0;

  &.opened {
    left: 0;
  }

  &.closed {
    left: 150vw;
  }

  transition-duration: 0.4s;

  background: $background-secondary-light;
  box-shadow: $box-shadow;

  z-index: 99999;

  padding: 50px;

  .header {
    display: flex;
    align-items: center;
    .title {
      font-size: larger;
    }
    .close {
      cursor: pointer;

      img {
        padding: 10px;
        height: 50px;
        width: 50px;
      }
    }
  }

  .steps {
    margin-top: 50x;
    max-width: 650px;

    .title {
      font-size: xx-large;
      font-weight: 500;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .previous {
        cursor: pointer;
        border-radius: $border-radius;
        padding: 5px;
        height: max-content;
        color: $dark;
        font-weight: 500;

        &:hover {
          background-color: $light-gray;
        }
      }
    }
  }

  .delete-guest {
    padding: 5px;
    cursor: pointer;
    margin: auto;
    margin-right: 10px;
  }

  .img-preview-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    .img-preview {
      position: relative;
      .delete {
        position: absolute;
        top: 0px;
        right: 00px;

        display: flex;
        align-items: center;
        justify-content: center;

        $dim: 25px;
        width: $dim;
        height: $dim;
        min-width: $dim;

        background-color: white;
        padding: 5px;
        border-radius: 100%;
      }

      cursor: pointer;

      .image {
        border-radius: $border-radius;
        margin: 10px 10px 0 0;
        height: 150px;
        width: 150px;
      }

      &:first-child,
      &:hover {
        .image {
          border: 5px solid $secondary;
        }
      }
    }
  }
}
