#support-section {
  @include flex(column, flex-start);

  gap: 10px;

  .title {
    font-weight: 500;
  }

  .btns-container {
    button {
      width: 100%;
    }
  }

  .group-lang {
    width: 100%;
  }

  .support-table {
    width: 100%;

    .support-item {
      @include flex(row, flex-start, space-between);

      gap: 15px;
      width: 100%;
      padding: 20px;
      border: 1px solid $light-gray;
      border-radius: $border-radius;
      cursor: pointer;

      .left {
        @include flex(row, center, space-between);

        gap: 35px;
        width: 95%;

        > div {
          @include flex(row, center, flex-start);

          width: 45%;
          max-width: 100%;

          overflow: hidden;
          white-space: pre-line;
        }
      }

      img {
        width: 25px;
        height: 25px;
      }

      .support-icon {
        width: 55px;
        height: 55px;
      }

      .cat-name {
        width: 60%;
      }

      .documents-item {
        max-height: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .document-details {
    white-space: pre-line;
  }
}

#faq-modal {
  @include flex(column, flex-start);

  gap: 10px;
  padding: 15px;

  .title {
    font-weight: 500;
  }

  .modal-form {
    width: 100%;

    .lang {
      margin-bottom: 20px;
    }

    .message {
      margin-bottom: 20px;
      width: 100%;

      &.bold {
        font-weight: 450;
      }
    }

    .textarea-docs {
      height: 400px;
    }

    #question {
      @include flex(column, flex-start);
      vertical-align: top;

      height: 60px;
    }

    #answer {
      height: 150px;
      vertical-align: top;
    }

    #category-icon {
      button {
        background: $white;
        border: solid 2px red;
      }
    }

    .img-preview-container {
      @include flex(row, flex-start, flex-start);

      gap: 15px;
      margin-bottom: 15px;

      .image {
        height: 80px;
        width: 80px;
        background-color: $light-gray;
        position: relative;

        .delete-icon {
          position: absolute;
          top: -10px;
          right: -10px;
          background-color: $white;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid $light-gray;

          img {
            width: 25px;
          }
        }
      }

      .img-light {
        background-color: $secondary;
      }
    }

    .error {
      color: $error;
    }
  }
  .btn-edit-doc{
    margin-top: 20px;
    // position: fixed;
    // bottom: 20px;
  }
}
