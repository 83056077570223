#col-census-info-modal {
  @include flex(column, flex-start);

  gap: 30px;
  width: 100%;
  padding: 20px;

  .show {
    @include flex(column, flex-start);

    gap: 5px;
    width: 100%;
  }

  .item-container {
    @include flex(column, flex-start, flex-start);

    width: 100%;
  }

  .item-content {
    @include flex(row, center, flex-start);

    gap: 5px;
    width: 100%;
    margin-bottom: 5px;

    img {
      width: 20px;
      margin-top: 25px;
    }
  }

  .rooms-container {
    @include flex(column, flex-start, flex-start);

    gap: 25px;
    width: 100%;

    .delete-room {
      align-self: flex-end;
    }
  }

  .poi-container {
    @include flex(row);

    gap: 25px;
    width: 100%;
  }

  img {
    width: 20px;
    margin-top: 25px;
  }

  .save-census {
    align-self: flex-end;
  }
}
