#modal-container {
  position: fixed;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.3);

  padding: 10px;

  .modal-middle {
    position: relative;

    width: 100%;
    max-width: 600px;
    overflow-y: hidden;

    .close-modal {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 10px 10px 40px 40px;

      background-color: white;
      border-top-right-radius: $border-radius;

      height: 80px;
      width: 80px;

      cursor: pointer;

      clip-path: polygon(0 0, 100% 0, 100% 100%);

      z-index: 999999;
    }

    .modal-content {
      border-radius: $border-radius;
      background-color: $white;
      box-shadow: $box-shadow;

      width: 100%;
      max-height: 90svh;
      overflow-y: scroll;

      padding-top: 50px;
    }
  }
}
