#account-settings {
  height: 100%;

  .header-container {
    .user-info {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 25px 0;

      .name {
        p {
          margin: 0;
        }
      }

      .user-icon {
        height: 35px;
        width: 35px;
      }
    }
  }

  .account-settings-container {
    height: 100%;
    display: flex;
    margin-top: 25px;
    gap: 15px;

    .sidenav {
      width: 400px;

      .menu-item {
        width: 100%;
        padding: 5px;
        border-radius: $border-radius;
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 10px;

        &.selected {
          font-weight: 500;
          background-color: $light-gray;
        }

        &:hover {
          background-color: $light-gray;
        }
        .menu-icon {
          height: 15px;
          margin-top: 2px;
        }
      }
      .hr {
        margin: 10px 0;
      }
    }

    .settings-content {
      width: 100%;
      display: flex;
      flex-direction: column;

      .hr {
        margin: 20px 0;
      }

      .title {
        font-weight: 500;
      }

      .collection-dev-table {
        .icon {
          padding: 5px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }

      .page {
        margin: 50px auto;
        display: flex;
        gap: 15px;
        align-items: center;

        .chevron {
          padding: 5px;
          height: 30px;
          cursor: pointer;

          &.disabled {
            cursor: unset;
            opacity: 0.3;
          }
        }
      }

      .apikey-list {
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        border: 1px solid $light-gray;
        border-radius: $border-radius;

        .apikey {
          padding: 20px;
          border-bottom: 1px solid $light-gray;
          position: relative;
          display: flex;
          flex-direction: column;

          justify-content: space-between;

          .apikey-delete {
            position: absolute;
            right: 5px;
            top: 5px;

            padding: 10px;

            height: 35px;
            width: 35px;

            cursor: pointer;

            display: none;
          }

          &:hover {
            background-color: $light-gray;

            .apikey-delete {
              display: block;
            }
          }
        }
      }

      .group-list {
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        border: 1px solid $light-gray;
        border-radius: $border-radius;

        .group {
          padding: 20px;
          border-bottom: 1px solid $light-gray;
          position: relative;
          display: flex;

          justify-content: space-between;

          &:hover {
            background-color: $light-gray;
          }
          &.clickable {
            cursor: pointer;
          }

          .not-verified {
            color: $error;
          }

          .group-dropdown {
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
      }
    }
  }
}
