#user-dropdown {
  $padding: 10px;
  color: $black;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-width: 400px;

  max-height: 80vh;
  overflow: scroll;

  p {
    margin: 0;
  }

  .block {
    padding: $padding;
    width: 100%;
    display: flex;
    flex-direction: column;

    .email {
      color: $muted;
      text-align: center;
    }

    .avatar {
      height: 25px;
      width: 25px;
    }
  }

  .group-title {
    width: 100%;
    padding: 0px $padding;
    margin-top: $padding;
    font-weight: bold;
  }

  .group-list {
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;

    .top-shadow {
      width: 100%;
      height: 15px;

      background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.2));
    }

    .bottom-shadow {
      width: 100%;
      height: 15px;

      background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
    }
  }

  .payment {
    padding: $padding;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: $light-gray;
    }
  }

  .group-filter {
    margin: 3px;
    position: relative;

    input {
      height: 25px;
      font-size: 13px;
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      height: 15px;

      opacity: 0.6;
    }
  }

  .group {
    padding: 10px $padding;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: $light-gray;
    }

    .not-verified {
      color: $error;
    }
  }

  .create-group {
    width: 100%;
    padding: 10px $padding;
    color: $muted;

    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: $light-gray;
    }

    img {
      margin-top: 2px;
      height: 15px;
      width: 15px;
    }
  }
}
