#nav {
  display: flex;
  justify-content: space-between;
  background-color: $dark;
  width: 100vw;
  padding: 15px 25px;

  .right-section {
    color: $white;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  #logo {
    margin: auto 0;
    height: 40px;
  }

  
}
