table {
  width: 100%;

  * {
    font-size: small !important;
  }

  th,
  td {
    padding: 10px;
  }

  thead {
    background-color: $dark;
    color: $white;
    border-radius: $border-radius;

    th {
      font-weight: normal;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: $secondary-light-white;
      }
    }
  }

  &.light {
    border: 1px solid $light-gray;

    thead {
      background-color: unset;
      color: unset;
    }

    th,
    td {
      border-bottom: 1px solid $light-gray;
    }

    th {
      font-weight: bold;
    }
  }
}
