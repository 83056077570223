// bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

// BASICS
@import "./fonts";
@import "./variables";
@import "./mixins";

// PAGES
@import "./pages/auth";
@import "./pages/accountsettings";
@import "./pages/group";
@import "./pages/homecollections";
@import "./pages/collection";
@import "./pages/dropship";
@import "./pages/support";
@import "./pages/emailService";

// DOM ELEMENTS
@import "./components/dom/button";
@import "./components/dom/link";
@import "./components/dom/input";
@import "./components/dom/scrollbar";
@import "./components/dom/selection";
@import "./components/dom/form";
@import "./components/dom/checkbox";
@import "./components/dom/textarea";
@import "./components/dom/select";
@import "./components/dom/datepicker";
@import "./components/dom/dropdownmenu";
@import "./components/dom/loader";
@import "./components/dom/modal";
@import "./components/dom/table";
@import "./components/dom/pagination";

// CUSTOM ELEMENTS
@import "./components/custom/nav";
@import "./components/custom/footer";
@import "./components/custom/logo";
@import "./components/custom/userdropdown";
@import "./components/custom/createcollection";
@import "./components/custom/collectioncard";
@import "./components/custom/collectionimages";
@import "./components/custom/createnft";
@import "./components/custom/mapsautocomplete";

// MODALS
@import "./modals/groupmodals";
@import "./modals/devmodals";
@import "./modals/nftmodals";
@import "./modals/collectioncensusmodal";

body {
  background: $background-secondary-light;
  color: $black;
  font-family: $font-primary;
  font-weight: $font-weight-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.h0 {
  height: 0;
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.filter-invert {
  filter: invert(1);
}

hr,
.hr {
  width: 100%;
  border-bottom: 1px solid $light-gray;

  &.light {
    border-color: $white;
  }
}

.hidden {
  display: none !important;
}

.tooltip-dark {
  border-radius: $border-radius;
  background-color: $dark;
  color: white;
}

.loaderFull {
  background: #14141457;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.table.noHover {
  tbody {
    tr {
      &:hover,
      th:hover,
      td:hover {
        background-color: inherit;
      }
    }
  }
}
