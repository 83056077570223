#invite-user-group-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    img {
      height: 30px;
      width: 30px;
    }
    .title {
      font-size: larger;
    }
  }

  .body {
    width: 100%;
    form {
      width: 100%;
      .line {
        input {
          width: 100%;
          margin: 0;
        }
        gap: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}

#remove-user-group-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
  }
}

#role-user-group-modal {
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .title {
    font-size: larger;
  }

  .buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
  }

  .roles {
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: $border-radius;

    .role {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: $light-gray;
      }

      .name {
        margin: 0;
        font-weight: 500;
      }

      &.selected {
        position: relative;
        border-left: 3px solid $primary;
        background-color: $light-gray;
      }
    }
  }
}
