#footer {
  padding: 30px 0;

  .footer-content {
    display: flex;
    justify-content: space-between;
    .lang-selection {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}
