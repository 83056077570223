.custom-select {
  background-color: transparent;
  border: 1px solid $secondary;

  border-radius: $border-radius;
  height: 40px;
  padding: 5px 20px;

  transition-duration: 0.2s;

  // text-align: center;

  &.small {
    height: 30px;
    padding: 1px 10px;
  }

  &:focus {
    outline: none !important;
    border: 2px solid $secondary;
  }

  &:disabled {
    opacity: 0.7;
  }
}
