.custom-button {
  color: $black;
  background-color: $primary;

  white-space: nowrap;
  border-radius: 8px;
  box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.05);
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition-duration: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 8px 26px;
  font-size: 16px ;

  position: relative;

  white-space: nowrap;

  &:hover {
    transform: scale(1.02);
  }

  .metadata {
    position: absolute;
    right: 10px;
    font-weight: lighter;

    .loader {
      margin-top: 5px;
      right: -20px;
    }
  }

  &.light {
    background-color: $white;
    box-shadow: $box-shadow;
    color: $dark;
  }

  &.error {
    background-color: $error;
    color: $white;
  }

  &.small {
    padding: 5px 30px;
    font-size: 14px;
    max-height: 30px;

    .loader {
      right: -35px;
    }
  }

  &.secondary {
    background-color: $primary;
  }

  &:disabled {
    cursor: default;
    opacity: 0.75;

    &:hover {
      transform: scale(1);
    }
  }

  &.toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 5px;
    background: none;
    padding: 0;
    color: $secondary;

    img {
      width: 20px;
    }
  }
}
