input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='password'] {
  background-color: transparent;
  border: 1px solid $dark;

  border-radius: $border-radius;
  height: 40px;
  padding: 5px 20px;

  font-size: 14px;

  transition-duration: 0.2s;

  &:focus {
    outline: none !important;
    border: 2px solid $light;
  }

  &:disabled {
    opacity: 0.7;
  }

  &.error {
    border: 2px solid $error;
  }
}
