form {
  display: flex;
  flex-direction: column;

  .col {
    display: flex;
    flex-direction: column;
  }

  input,
  textarea,
  #maps-autocomplete {
    margin-bottom: 20px;
  }

  label {
    color: $muted;
    margin-bottom: 5px;
    font-family: $font-primary;
    font-size: 14px;
  }

  p {
    font-family: $font-primary;
  }
}
