.pagination-container {
  width: 100%;
  background: $background-secondary-light;
  padding: 8px;
  border-radius: 10px;
  margin-top: 30px;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;

    .items-per-page {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 15px;
    }

    .total-items {
      display: flex;

      gap: 5px;
    }

    .number-of-pages {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 5px;

      .double {
        img {
          width: 18px;
        }
      }

      .numbers {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 5px;
      }
    }

    .page-number {
      width: 50px;
      padding: 0 10px;
      text-align: center;
    }

    img {
      width: 18px;
    }

    .disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
