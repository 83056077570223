// COLORS

$background: #f5f5f5;
$black: #1c1c1c;
$white: #fff;
$muted: #808080;
$disabled: #d7d7d7;
$gray: #9d9d9d;
$light-gray: #e6e6e6;

$accent: #d5b9b2ff;
$light: #a9f0a4;
$secondary: #171717;
$primary: #8ce786;
$dark: #171717;
$error: #e63946;
$bright-dark: #a9f0a4;

$secondary-light-white: rgb(247, 247, 255);

$background-secondary-light: radial-gradient(
  343.71% 137.42% at 100% 85.97%,
  #efeefb 26.06%,
  #f9f9ff 73.72%
);

$gold: #c7a900;

// OTHERS

$border-radius: 5px;
$box-shadow: 0px 0px 24px rgba(150, 150, 150, 0.4);

// BREAKPOINTS
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;