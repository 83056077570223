#nft-detail-modal {
  padding: 0 25px 25px 25px;

  max-height: 80vh;
  overflow: scroll;

  .title {
    margin-bottom: 5px;
    font-size: large;
  }

  > div {
    display: flex;
    gap: 10px;
  }

  p {
    margin: 0;

    &.title {
      margin-top: 10px;
      font-weight: 500;
    }
  }

  table {
    td {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
