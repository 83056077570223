@mixin flex($direction, $align: center, $justify: center, $breakpoint: 0px) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;

  @media only screen and (max-width: $breakpoint) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}