@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      width: 100%;
    }
  }
}

.react-datepicker__month {
  height: 200px;
}
